import axios                    from '@/api/api';
import {
    AssemblyType,
    CartResponse,
    CheckoutInfo,
    CheckoutThankYouData,
    ProductLinkMap,
    UpdatePaymentTypeResponse,
} from '@/types/cart';
import { PaymentInfoResponse }  from '@/types/payments';
import { ShippingInfoResponse } from '@/types/shipping';
import getRoute                 from '@/utils/routing';

class CartApi {
    async getCart() {
        return axios.get<CartResponse>(
            getRoute('api_v1_carts_get_cart'),
        );
    }

    async updateCartItemQuantity(itemId: string, position: number, oldQuantity: number, newQuantity: number) {
        return axios.patch(
            getRoute('cart_update_quantity'),
            {
                itemId:   itemId,
                quantity: newQuantity,
            },
        );
    }

    async getCartTotalAmount(cartId: number | string, countryCode: string, postalCode: string) {
        return axios.get<{ price: number; }>(
            getRoute(
                'api_v1_carts_total_amount',
                {
                    cartId,
                    countryCode,
                    postalCode,
                    r: Math.random(),
                },
            ),
        );
    }

    async updateCartItemComment(itemId: string, comment: string) {
        return axios.patch(
            getRoute('api_v1_cart_item_patch_cart_item', { itemId }),
            {
                description: comment,
            },
        );
    }

    async removeCartItem(itemId: string) {
        return axios.delete(
            getRoute('cart_remove_item'),
            {
                data: { id: itemId },
            },
        );
    }

    /**
     * Product map is used to generate the product preview cards below the cart table on main cart page
     */
    async getProductMap() {
        return axios.get<ProductLinkMap>(getRoute('api_v1_carts_get_product_link_map'));
    }

    async getShippingInfo() {
        return axios.get<ShippingInfoResponse>(
            getRoute('api_v1_carts_get_shipping_info'),
        );
    }

    async updateShippingInfo(shippingMethodId: number) {
        return axios.post(
            getRoute('api_v1_carts_update_shipping_type'),
            { typeId: shippingMethodId },
        );
    }

    async flushCartMcCampaignId(campaignId: string) {
        return axios.post(
            getRoute('api_v1_carts_flush_mc_campaign_id'),
            { campaignId: campaignId },
        );
    }

    async getCartPaymentInfo() {
        return axios.get<PaymentInfoResponse>(
            getRoute('api_v1_carts_get_payment_info'),
        );
    }

    async updatePaymentMethod(paymentMethodId: number) {
        return axios.post<UpdatePaymentTypeResponse>(
            getRoute('api_v1_carts_update_payment_type'),
            { paymentMethodId },
        );
    }

    async addTrustedShopInsurance(cartId: number) {
        return axios.post(
            getRoute(
                'api_v1_carts_add_trusted_shops_insurance',
                { cartId },
            ),
        );
    }

    async removeTrustedShopInsurance(cartId: number) {
        return axios.delete(
            getRoute(
                'api_v1_carts_remove_trusted_shops_insurance',
                { cartId },
            ),
        );
    }

    async getCheckoutInfo() {
        return axios.get<CheckoutInfo>(
            getRoute('api_v1_carts_get_checkout'),
        );
    }

    async updateCartCheckoutComment(comment: string) {
        return axios.post(
            getRoute('cart_update_comment'),
            {
                comment: comment,
            },
        );
    }

    async getCartItemsCount() {
        return axios.get<number>(
            getRoute('api_v1_carts_get_cart_item_count'),
        );
    }

    async applyVoucher(voucherCode: string, cartId: string) {
        return axios.post(
            getRoute('api_v1_carts_set_voucher', {cartId: cartId}),
            {
                code: voucherCode,
            },
        );
    }

    async removeVoucher(cartId: string) {
        return axios.delete(
            getRoute('api_v1_carts_remove_voucher', { cartId: cartId }),
        );
    }

    async getCalculatedAssemblyPrice(assemblyTypeId: number) {
        return axios.get<{ price: number }>(
            getRoute('api_v1_assembly_get_price', { id: assemblyTypeId }),
        );
    }

    async addAssemblyToCart(assemblyTypeId: number) {
        return axios.post(
            getRoute('api_v1_assembly_select', { id: assemblyTypeId }),
        );
    }

    async confirmOrder(payload: FormData) {
        return axios.post(
            getRoute('api_v1_carts_confirm'),
            payload,
        );
    }

    async getThankYouData() {
        return axios.get<CheckoutThankYouData>(getRoute('api_v1_carts_thank-you'));
    }

    async getAssemblyList() {
        return axios.get<AssemblyType[]>(getRoute('api_v1_assembly_list'));
    }
}

export default (new CartApi());

import { AxiosError, AxiosResponse } from 'axios';
import { defineStore }               from 'pinia';
import { computed, ref }             from 'vue';

import CartApi                from '@/api/cart-api';
import  {
    CartResponse,
    CartVoucherInfo,
    ProductLinkMap,
}                             from '@/types/cart';
import { captureError }       from '@/utils/error-logger';
import { pushEcommerceEvent } from '@/utils/gtm';

const useCartStore = defineStore('carts', () => {
    const cart = ref<CartResponse | null>(null);
    const cartIsUpdating = ref<boolean>(false);
    const cartItemCount = ref<number>(0);
    const cartProductMap = ref<ProductLinkMap | null>(null);
    const voucher = ref<CartVoucherInfo | null>(null);

    const cartErrorMessages = ref<string[]>([]);
    const cartWarningMessages = ref<string[]>([]);
    const cartSuccessMessages = ref<string[]>([]);

    // If true, forces validation of the selected address on that page
    const hasAddressError = ref<boolean>(false);

    const cartId = computed(() => {
        return cart.value ? cart.value.id : null;
    });

    function reset(): void {
        cart.value = null;
        cartIsUpdating.value = false;
        cartItemCount.value = 0;
        cartProductMap.value = null;
        voucher.value = null;
        cartErrorMessages.value = [];
        cartWarningMessages.value = [];
        hasAddressError.value = false;
    }

    async function fetchCart(): Promise<AxiosResponse<CartResponse>> {
        return CartApi.getCart()
            .then(response => {
                if (response.status === 204) {
                    cart.value = null;
                    cartItemCount.value = 0;

                    return response;
                }

                cart.value = response.data;

                if (response.data && response.data.voucher) {
                    voucher.value = response.data.voucher;
                } else {
                    voucher.value = null;
                }

                if (cart.value) {
                    cartItemCount.value = cart.value.cartItems.reduce((accumulator, currentValue) => accumulator + (currentValue?.quantity || 0), 0);
                } else {
                    cartItemCount.value = 0;
                }

                if (cart.value && cart.value.messages.length) {
                    setCartSuccessMessages(...cart.value.messages);
                }

                return response;
            })
            .catch((error: AxiosError) => {
                cart.value = null;
                cartItemCount.value = 0;

                if (error.response && error.response.status !== 404) {
                    captureError(error);
                }

                return Promise.reject(error);
            });
    }

    async function getCart(): Promise<CartResponse | null> {
        if (cart.value === null) {
            await fetchCart();
        }

        return new Promise(resolve => resolve(cart.value));
    }

    async function flushCartMcCampaignId(campaignId: string) {
        return CartApi.flushCartMcCampaignId(campaignId)
            .catch((error: AxiosError) => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function fetchCartItemCount(): Promise<AxiosResponse<number>> {
        return CartApi.getCartItemsCount()
            .then(response => {
                cartItemCount.value = response.data;

                return response;
            })
            .catch((error: AxiosError) => {
                cartItemCount.value = 0;

                captureError(error);

                return Promise.reject(error);
            });
    }

    async function fetchCartProductMap(): Promise<AxiosResponse<ProductLinkMap>> {
        return CartApi.getProductMap()
            .then(response => {
                cartProductMap.value = response.data;

                return response;
            })
            .catch((error: AxiosError) => {
                cartProductMap.value = null;

                captureError(error);

                return Promise.reject(error);
            });
    }

    async function updateCartItemQuantity(itemId: string, position: number, quantity: number, newQuantity: number) {
        return CartApi.updateCartItemQuantity(itemId, position, quantity, newQuantity)
            .then(async response => {
                await fetchCart();

                if (newQuantity === 0) {
                    pushEcommerceEvent('remove_from_cart', cart.value?.cartItems || [], cart.value?.cartAmount);
                }

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function updateCartItemComment(itemId: string, position: number, comment: string) {
        return CartApi.updateCartItemComment(itemId, comment)
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function removeCartItem(itemId: string) {
        return CartApi.removeCartItem(itemId)
            .then(async response => {
                await fetchCart();

                pushEcommerceEvent('remove_from_cart', cart.value?.cartItems || [], cart.value?.cartAmount);

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function addAssemblyToCart(assemblyId: number) {
        return CartApi.addAssemblyToCart(assemblyId)
            .then(async () => {
                await fetchCart();
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    function setCartIsUpdating(isUpdating: boolean): void {
        cartIsUpdating.value = isUpdating;
    }

    function getCartErrorMessages(): string[] {
        return cartErrorMessages.value;
    }

    function setCartErrorMessages(...messages: string[]): void {
        cartErrorMessages.value.push(...messages);
    }

    function clearCartErrorMessages(): void {
        cartErrorMessages.value = [];
    }

    function getCartWarningMessages(): string[] {
        return cartWarningMessages.value;
    }

    function setCartWarningMessages(...messages: string[]): void {
        cartWarningMessages.value.push(...messages);
    }

    function clearCartWarningMessages(): void {
        cartWarningMessages.value = [];
    }

    function setCartSuccessMessages(...messages: string[]): void {
        cartSuccessMessages.value.push(...messages);
    }

    function getCartSuccessMessages(): string[] {
        return cartSuccessMessages.value;
    }

    function clearCartSuccessMessages(): void {
        cartSuccessMessages.value = [];
    }

    function cartHasInvalidVoucher(): boolean
    {
        return !!(
            cart.value?.vouchersEnabled &&
            typeof cart.value?.voucher?.error === 'string' &&
            cart.value?.voucher?.error !== ''
        );
    }

    return {
        cart,
        cartIsUpdating,
        cartItemCount,
        cartId,
        hasAddressError,
        voucher,
        reset,
        fetchCart,
        fetchCartItemCount,
        fetchCartProductMap,
        getCart,
        flushCartMcCampaignId,
        updateCartItemQuantity,
        updateCartItemComment,
        removeCartItem,
        setCartIsUpdating,
        getCartErrorMessages,
        setCartErrorMessages,
        getCartWarningMessages,
        setCartWarningMessages,
        clearCartErrorMessages,
        clearCartWarningMessages,
        setCartSuccessMessages,
        getCartSuccessMessages,
        clearCartSuccessMessages,
        cartHasInvalidVoucher,
        addAssemblyToCart,
    };
});

export default useCartStore;
